import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const SubmitDialog = ({ open, onClose }) => {
  const [link, setLink] = useState('')
  const [name, setName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const validateAndSubmit = async () => {
    if (link.startsWith("https://open.spotify.com/playlist/") && name.trim() !== '') {
      try {
        // Replace this with the endpoint where you handle appending to the playlists.txt file
        const token = localStorage.getItem('token');
        const response = await fetch("/api/append-playlist", {
          method: 'POST',
          body: JSON.stringify({ link, name }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
          },
          credentials: 'include'
        });
        if (response.ok) {
          onClose();
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.error || 'Something went wrong');
        }
      } catch (error) {
        setErrorMessage(error.message || 'Something went wrong');
      }
    } else {
      setErrorMessage('Please enter a valid Spotify playlist link and playlist name.');
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Submit Spotify Playlist</DialogTitle>
      <DialogContent>
        <TextField
          spellCheck={false}
          id="outlined-basic"
          variant="outlined"
          label="Playlist Link"
          fullWidth
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <TextField
          spellCheck={false}
          id="outlined-basic"
          variant="outlined"
          label="Playlist Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={validateAndSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SubmitDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export { SubmitDialog };